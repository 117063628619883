import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import api from "../../services/api"
import { makeStyles } from "@material-ui/core/styles";
import {Bar} from 'react-chartjs-2'
import {Pie} from 'react-chartjs-2'


const App = () => {
//console.log('Dentro do frontend')

let [dadosTempoMedio,setDadosTempoMedio] =  useState(['']) 

let [dadosTop10Solicitantes,setDadosTop10Solicitantes] = useState([''])

let [dadosTickets,setDadosTickets] = useState([''])

let [dadosStatusFilas,setDadosStatusFilas] = useState([''])

//Função que realiza o post e seta o estado dos graficos com os dados do POST
useEffect(() => {
  async function getData(values) {
    
    const postDATA =  await api.post('/barchart', values)
    // console.log(postDATA.data)
  

     var data = postDATA.data
     var labelTempoMedio = []
     var dadosjsonTempoMedio = []

     //Itera os dados do Tempo Medio de Atendimento

    
     
    await data['TempoMedioAtendimentoOperadorFilaMensal'][0].forEach(element => {
      // console.log(element)
       labelTempoMedio.push(element['1'])
       dadosjsonTempoMedio.push(element['0'])
     });
   
     var dadosTempoMedio = {
       labels: labelTempoMedio,
       datasets: [
         {
           barPercentage: 0.5,
           barThickness: 55,
           maxBarThickness: 75,
           minBarLength: 8,
           label: 'Tempo Medio (Minutos)',
           data: dadosjsonTempoMedio,
           fill: true,
           backgroundColor:[
           'rgb(153, 0, 0)',
           'rgb(255, 99, 132)',
           'rgb(54, 162, 235)',
           'rgb(255, 205, 86)',
           'rgb(76, 175, 80)',
           'rgb(255, 255 , 0)',
           'rgb(54, 244, 209)',
           'rgb(188, 54 ,244)',
           'rgb(0 , 255, 90)',
           'rgb(245 , 0 , 87)'],
           pointBorderColor:"#8884d8",
           pointBorderWidth:2,
           pointRadius:4,
           tension: 0.4
         },
       ],
     };
     

     var labelTop10 = []
     var numClienteTop10 = []
     // itera os dados do Top 10 Num Solicitantes
     await data['Top10Solicitantes'][0].forEach(element => {
      // console.log(element)
       numClienteTop10.push(element['0'])
       labelTop10.push(element['1']+": "+ element['0'])
     })
     var dadosTop10 = {
      labels: labelTop10,
      datasets: [
        {
          label: 'Top 10 Solicitantes',
          data: numClienteTop10,
          backgroundColor: [
            'rgb(153, 0, 0)',
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(76, 175, 80)',
            'rgb(255, 255 , 0)',
            'rgb(54, 244, 209)',
            'rgb(188, 54 ,244)',
            'rgb(0 , 255, 90)',
            'rgb(245 , 0 , 87)'

          ],
        }
      ]
    };

    var arrayTickets = {
      ticketsAbertosTotal: data['ticketsAbertosTotal'][0][0],
      ticketsAguardandoAtendimento: data['ticketsAguardandoAtendimento'][0][0],
      ticketsFechadosHoje: data['ticketsFechadosHoje'][0][0]
    } 
   var dataStatusTickets = [arrayTickets['ticketsAbertosTotal'],arrayTickets['ticketsAguardandoAtendimento'],arrayTickets['ticketsFechadosHoje']]
    var dadosTickets = {
      labels: ['Abertos: '+arrayTickets['ticketsAbertosTotal'],'Aguardando Atendimento: ' + arrayTickets['ticketsAguardandoAtendimento'],'Fechados: ' + arrayTickets['ticketsFechadosHoje']],
      datasets: [
        {
          label: 'Status Tickets',
          data: dataStatusTickets, //Variavel array tickets aqui
          backgroundColor: [
            'rgb(54, 162, 235)',
            'rgb(153, 0, 0)',
            'rgb(255, 205, 86)'
          ],
        }
      ]
    };


    var labelStatusFila = []
    //var dadosCountFila = []

    //Itera os dados do Tempo Medio de Atendimento

    var dadosStatusFila = {
      labels: ['Fechados','Abertos'],
      datasets: [

      ],
    };
   var jsonStatusFila = { 
      filas: {

      }
     
   }
   
    //console.log(data['statusFilasMensal'][0])
    //console.info(data['statusFilasMensal'][0])
    await data['statusFilasMensal'][0].forEach( element => {
      //console.log(element)
      var nomeFila = element['1']
      console.log(element)
      if(jsonStatusFila.filas[nomeFila] === undefined){
        jsonStatusFila.filas[nomeFila] = []
       // console.log('teste ' + element['0'])
        jsonStatusFila.filas[nomeFila].push(element['0'])//(element['0'])
      }else{
      //  console.log('else ' + element['0'])
       console.log(nomeFila)
      // console.log(jsonStatusFila.filas[nomeFila]['0']+element['0'])
        jsonStatusFila.filas[nomeFila].push(jsonStatusFila.filas[nomeFila]['0']+element['0']) //(element['0'])
        
      }

      labelStatusFila.push(element['1'])
      
     // labelStatusFila.push(element['1'])
      
     // dadosCountFila.push(element['0'])
    //  var datasetFilaMensal =  {
    //     barPercentage: 0.5,
    //     barThickness: 55,
    //     maxBarThickness: 75,
    //     minBarLength: 8,
    //     label: element['1'],
    //     data: [element['0'],0,0,0],
    //     fill: true,
    //     backgroundColor:[
    //       'rgb(153, 0, 0)',
    //       'rgb(255, 99, 132)',
    //       'rgb(54, 162, 235)',
    //       'rgb(255, 205, 86)',
    //       'rgb(76, 175, 80)',
    //       'rgb(255, 255 , 0)',
    //       'rgb(54, 244, 209)',
    //       'rgb(188, 54 ,244)',
    //       'rgb(0 , 255, 90)',
    //       'rgb(245 , 0 , 87)'],
    //     pointBorderColor:"#8884d8",
    //     pointBorderWidth:2,
    //     pointRadius:4,
    //     tension: 0.4
    //   }
    //   dadosStatusFila.datasets.push(datasetFilaMensal)
      
    });
  


   await fixJson(jsonStatusFila,labelStatusFila,dadosStatusFila)


     //var dadosArray = [dadosTempoMedio]
    // console.log(dadosStatusFila)
    // console.log(labelStatusFila)
     //console.info(dadosStatusFila)
     setDadosTempoMedio(dadosTempoMedio)
     setDadosTop10Solicitantes(dadosTop10)
     setDadosTickets(dadosTickets)
     setDadosStatusFilas(dadosStatusFila)
    // setDados(newdata)
     //setDados(values)
    }

   // console.log('dentro do use estate')
  getData()
}, []);


async function fixJson(jsonStatusFila,labelStatusFila,dadosStatusFila) {
 // console.log(jsonStatusFila.filas[labelStatusFila['0']].length)

  await labelStatusFila.forEach(element => {

   // var count=0
      if(jsonStatusFila.filas[element].length === 1){
        //console.log(jsonStatusFila.filas[element].length)
        jsonStatusFila.filas[element].push(0)
       // datasetFilaMensal.label=labelStatusFila[count]
       // count++
     
      }

      console.log(jsonStatusFila.filas[element])
       
  });
  var count = 0
  var arrayColors=[
   'rgb(153, 0, 0)',
   'rgb(255, 99, 132)',
   'rgb(54, 162, 235)',
   'rgb(255, 205, 86)',
   'rgb(76, 175, 80)',
   'rgb(255, 255 , 0)',
   'rgb(54, 244, 209)',
   'rgb(188, 54 ,244)',
   'rgb(0 , 255, 90)',
   'rgb(245 , 0 , 87)']
  Object.keys(jsonStatusFila.filas).forEach(key => {
    var datasetFilaMensal =  {
      barPercentage: 0.5,
      barThickness: 55,
      maxBarThickness: 75,
      minBarLength: 8,
      label: '',
      data: [],
      fill: true,
      backgroundColor:[arrayColors[count]],
      pointBorderColor:"#8884d8",
      pointBorderWidth:2,
      pointRadius:4,
      tension: 0.4
    }
 //  console.log(key)
  // console.log(jsonStatusFila.filas[key])
//   datasetFilaMensal.data = []
   datasetFilaMensal.label = key
   datasetFilaMensal.data.push(jsonStatusFila.filas[key][0])
   datasetFilaMensal.data.push(jsonStatusFila.filas[key][1])
  // console.log(datasetFilaMensal)
   dadosStatusFila.datasets.push(datasetFilaMensal)

   count++
   })
}
//console.log('teste')
  //Options das instancias do ChartJS
  const options = {maintainAspectRatio: false,

  }

  const optionsStatusFilas = {maintainAspectRatio: false,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Tickets',
          ticks:{
            beginAtZero: true
          }
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Fila',
          barThickness: 0.3,
          barPercentage: 0.7
        }
      }],
    }     
  }
  var Top10Options =  {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: '10',
          fontSize: '10',
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          fontColor: '#666',
          fontStyle: 'normal',
          usePointStyle: true
        },
      },
      title: {
        display: true,
        position:'top',
        text: '                                                                                      Top 10 Solicitantes', // espaço em branco para centralizar.
        align: 'center'
      }
    }
  }

  var TicketOptions =  {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: '15',
          fontSize: '12',
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          fontColor: '#666',
          fontStyle: 'normal',
          usePointStyle: true
        },
      },
      title: {
        display: true,
        position:'top',
        text: '            Status dos Tickets (Dia)', // Espaço em branco para alinhar com o gráfico.
        align: 'start'
        
      }
    }
  }

//Inicio do CSS
  const useStyles = makeStyles(theme => ({
    tickets: {
      //paddingTop: theme.spacing(3),
     // paddingBottom: theme.spacing(3),
       marginLeft: '-50px',
       //marginRight: 'auto',
   //   borderStyle: "Ridge",
    //  borderColor: '#990000',
      //borderWidth: 5,
      maxWidth:400,
      maxHeight:400,
     // marginLeft:'2px',
      left: theme.spacing(1),
      position: "inherit", //"fixed"
      width: "50%",
      height: "100%",
    },
    top10Ligador: {
     // paddingTop: theme.spacing(3),
     // paddingBottom: theme.spacing(3),
      //margin: theme.spacing(2),
      left: theme.spacing(1),
      marginLeft: 'auto',
      //marginRight: 'auto',
      position: "relative", //"sticky",
      maxWidth: 755,
      maxHeight:400,
      width: "100%",
      height: "100%",
    //  borderLeftStyle: "Ridge",
    //  borderLeftColor: '#990000',
    //  borderLeftWidth: 5,

    },
    tempoMedio: {
      position: "relative",
      borderStyle: "Hidden",
      borderWidth: 5,
      maxWidth: 800,
      maxHeight:550,
      width: "100%",
      height: "100%",
      marginLeft: 'auto',
      marginRight: 'auto',

    },
    container: {
      position: "absolute",
      Width: '80%'
    },
    divTitle: {
        position: 'relative',
        left: '45%', //centraliza o titulo
        top: '10%',
        maxWidth: 400
    },
    divSubtitle: {
      position: 'relative',
      left: '40%', //centraliza o titulo
      top: '10%',
      fontFamily: "Helvetica",
      maxWidth: 400
  },
    pieCharts:{
      display:'flex',
      flexDirection: 'row',
    //  flexWrap: 'wrap',
      marginTop: '50px',
      marginBottom:'50px',
     // marginRight:'20px',
     // borderStyle: "Inset",
     // borderColor: '#990000',
     // borderWidth: 5,
    },
    StatusFilas: {
      position: "relative",
      borderStyle: "Hidden",
      borderWidth: 5,
      maxWidth: 800,
      maxHeight:350,
      width: "100%",

      marginLeft: 'auto',
      marginRight: 'auto',

    },
    containerTempoMedio:{
     // borderStyle: "Ridge",
     // borderColor: '#990000',
     // borderWidth: 5,
    },
    containerFilaMensal:{
      //borderStyle: "Ridge",
      //borderColor: '#990000',
     // borderWidth: 5,
    },
    h1Title: {
      color: '#990000',
      
    },
  }));
  const classes = useStyles()
  return (
    <Container className={classes.container}>
        <Container className={classes.containerTempoMedio}>
         <div  className={classes.divTitle}> <h1 className={classes.h1Title}>   Dashboard Life-Sip </h1> </div>
         <h3 className={classes.divSubtitle}> Tempo médio por Operador nos ultimos 30 dias </h3>
      <div className={classes.tempoMedio} > <Bar width={50} height={300}  data={dadosTempoMedio}  options={options}/></div>     
      </Container>
     <Container className={classes.pieCharts}>
    <div className={classes.tickets} ><Pie width={200} height={200} data={dadosTickets}  options={TicketOptions} />  </div>
    <div className={classes.top10Ligador}><Pie width={200} height={200} data={dadosTop10Solicitantes}  options={Top10Options} />  </div>

    </Container >
    <Container className={classes.containerFilaMensal}>
    <h3 className={classes.divSubtitle}>Tickets fechados e abertos nos ultimos 30 dias </h3>
    <div className={classes.tempoMedio} > <Bar width={50} height={300}  data={dadosStatusFilas}  options={optionsStatusFilas}/></div>
    </Container>
    </Container>) 
}


export default App


