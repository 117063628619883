import React, { useState, useEffect, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import api from "../../services/api"
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import { Link, Typography } from "@material-ui/core";
import Linearprogress from "@material-ui/core/LinearProgress"
import axios from "axios"
import pdf from '../../assets/Documento-Envio-Msg-Massa-Whatsapp-LIFESIP.pdf'


const App = () => {

	var creditos = ''
	//const [mensagem, setMensagem] = useState({mensagem: "" , });
	const [csvLS, setCSV] = useState({ csv: '', mensagem: '', userName: '', userId: '', creditos: '' })
	const [creditosPost, setCreditos] = useState()
	const [countCreditosMensais, setCountCM] = useState('')
	const [hidden, setHidden] = useState('none')
	const [hiddenOnSubmit, setHiddenOnSubmit] = useState('flex')
	const [disable, setDisable] = useState(false);
	const handleChangeInput = e => {
		setCSV({ ...csvLS, [e.target.name]: e.target.value });

	};

	const useStyles = makeStyles(theme => ({
		paper: {
			marginTop: theme.spacing(8),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
		},
		form: {
			width: "100%", // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
				
		},
		Titulo: {
			color: '#990000',
			display: 'flex',
			justifyContent: 'center',
			alignContent: "center",
			marginTop: '45px',
			marginBottom: '15px',
		},
		creditos: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignContent: "center",

		},
		count: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignContent: "center",


		},
		divHidden: {
			display: hidden,
		},
		divHideafterSubmit:{
			display: hiddenOnSubmit,
		},
		Info:{
			backgroundColor: '#0288d1',
			justifyContent: 'center',
			border: 'solid',
			borderWidth: '4px',
			borderColor: '#1d2438',
		},
		infoTitle:{
			alignSelf:'center',
			fontWeight: 600,

		},
		infoText:{
			textAlign:'center'
		},
		infoLink:{
			fontWeight: 600,
			alignSelf: 'Center',
			textAlign:'center',
			color:'#990000'
		}
	}));

	const { user: loggedInUser } = useContext(AuthContext);

	useEffect(() => {
		async function getCreditos() {
			await setUser()
			var post = await api.post("/getCreditos",)
			var dadosCount = await api.post("/getCountCreditos",)
			setCountCM("Mensagens enviadas no mês: " + dadosCount.data.count)
			console.log(dadosCount)
			console.log(dadosCount.data)
			setCreditos("Creditos: " + post.data.creditos)
			setCSV({ ...csvLS, creditos: post.data.creditos })
			//return "Creditos: " + post.data.creditos
			


			return 0
		}
		async function getCount(){
			var dadosCount = await api.post("/getCountCreditos",)
			await setCount(dadosCount)
			console.log(countCreditosMensais)
			console.log(dadosCount.data.count)
		}
		 getCreditos()
		// getCount()
		
		
	}, []
	)

	async function setCount(dados){
		setCountCM("Mensagens enviadas no mês: " + dados)
		console.log(countCreditosMensais)
		return 0
	}
	async function setUser() {
		//console.log(loggedInUser.name)
		//console.log(loggedInUser)
		//setCSV({...csvLS, userName: loggedInUser.name})
		//setCSV({...csvLS, userId: loggedInUser.id})
		setCSV({
			userName: loggedInUser.name,
			userId: loggedInUser.id

		})
		return loggedInUser.id
	}

	const handleSubmit = async (e) => {


		try {
			e.preventDefault()

			if (csvLS.userId === null || csvLS.userId === '') {
				await setUser()
			}
			if(!csvLS.mensagem || csvLS.mensagem === '' || csvLS.mensagem.length === 0){
				toast.error("Por favor digite uma mensagem")
				window.location.reload()
				return 0
			} else if (csvLS.csv === null || csvLS.csv === '' || csvLS.csv.length === 0) {
				toast.error("Nenhum arquivo CSV foi encontrado.")
				window.location.reload()
				return 0
			}else{
				toast.info("Transferindo o CSV para o envio das mensagems em massa. \n Aguarde um momento...", {
					autoClose: 4000,
				})			
			}

			setHidden('block')
			setHiddenOnSubmit('hidden')
			toast.info("Realizando o envio das mensagens... \n Por favor aguarde...", {
				delay: 5200,
				autoClose: 7000,
			})

			var resposta = await api.post("/sendCSV", csvLS)


			if (resposta.status === '200') {
				toast.success("Mensagens enviadas com sucesso! \n Atualizando a página...",{
					delay: 4000
				})
				await new Promise(resolve => setTimeout(resolve, 2000))
				setHidden('none')
				await new Promise(resolve => setTimeout(resolve, 4000));
				window.location.reload()
			}
		} catch (error) {
			toast.error("Ocorreu um erro ao enviar as mensagens, por favor entre em contato com o Suporte.")
			await new Promise(resolve => setTimeout(resolve, 2000));
			//console.log(resposta)
			window.location.reload()
		}
	}

	const handleFiles = e => {

		var reader = new FileReader();
		console.log(e.target.files[0].type)
		console.log(e.target.files[0].type == "application/vnd.ms-excel")
		if (e.target.files[0].type === "application/vnd.ms-excel" || e.target.files[0].type === "text/comma-separated-values" || e.target.files[0].type === "text/csv" || e.target.files[0].type === "application/csv") {
			reader.onload = function (e) {
				// Use reader.result
				//alert(reader.result)
				// var csvResult = reader.result
				//alert(csvResult)
				console.log(reader.result)
				setCSV({ ...csvLS, csv: reader.result })
			}

			reader.readAsText(e.target.files[0]);

			//console.log(e.target.files)
		} else {
			toast.error("Só é aceito arquivos do tipo '.csv'.")
			e.target.files = null
			e.target.value = ''
		}

	}//component="h2"
	const classes = useStyles()

	return (<Container><Typography variant="h5" className={classes.Titulo} > Envio de Mensagem em Massa </Typography>
		<Container component="main" maxWidth="xs"><form className={classes.form} noValidate onSubmit={handleSubmit}>

			<input type={'file'} onChange={handleFiles} accept='.csv'></input>

			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="mensagem"
				label="Digite sua mensagem..."
				name="mensagem"
				value={csvLS.mensagem}
				onChange={handleChangeInput}
				autoComplete="mensagem"
				autoFocus
			/>
<div className={classes.divHideafterSubmit}>
			<Button
				disabled={disable}
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			>
				Upload
			</Button>
			</div>
			<div className={classes.divHidden} >
				<p>Por favor aguarde...</p>
				<Linearprogress color="primary" />
			</div>

		<Typography variant="subtitle2" className={classes.creditos} > {creditosPost} </Typography>
		<Typography variant="subtitle2" className={classes.count} > {countCreditosMensais} </Typography>
		<div className={classes.Info}>
		<Typography align="center" className={classes.infoTitle} >Informativo</Typography>
		<Typography className={classes.infoText} >O arquivo CSV deve possuir pelo menos os campos “numero,nome” nesta respectiva ordem. 
			Segue exemplo em nossa documentação.
			
		</Typography>
		<Typography className={classes.infoLink}><Link href={pdf} target="_blank" align="center" className={classes.infoLink}>Documentação Envio em Massa Life-Sip</Link> </Typography>
		
		</div>
		</form>

</Container>
	</Container>)
}

export default App
